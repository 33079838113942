.GalleryTv {
    
    width: 100%;
    max-width: 570px;
    margin: 0 auto;
    margin-top: 70px;
    
}

.GalleryTvItems {
    // background-color: #FECFCF;
    
    display: flex;                      // display:         flex | inline-flex;
    flex-direction: row;                // flex-direction:  row | row-reverse | column | column-reverse;
    flex-wrap: wrap;                    // flex-wrap:       nowrap | wrap | wrap-reverse;
    align-items: flex-start;         // align-items:     flex-start | flex-end | center | baseline | stretch;
    align-content: flex-start;              // align-content:   flex-start | flex-end | center | space-between | space-around | stretch;
    justify-content: space-between;     // justify-content: flex-start | flex-end | center | space-between | space-around;
    
    @media (min-width: $screen-md-min) {
        // margin: 0 -22px;
    }
    
    .item {
        margin-bottom: 20px;
        order: 0;
        align-self: auto;               // align-self:      auto | flex-start | flex-end | center | baseline | stretch;
        flex: 0 0 47%;                     // flex:            none | [ <'flex-grow'> <'flex-shrink'>? || <'flex-basis'> ];
    }
    
    .line1 {
        text-align: center;
        text-transform: uppercase;
    }
    
    .line2 {
        text-align: center;
        position: relative;
        top: -3px;
    }
    
}