$navbarContact-color: $color-1 !default;
$navbarContact-link-color: $color-1 !default;
$navbarContact-link-color-hover: $color-2 !default;

.NavbarContact {
    
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#e0e0e0+0,f2f2f2+42,e8e8e8+100 */
    // background: #e0e0e0; /* Old browsers */
    // background: -moz-linear-gradient(left,  #e0e0e0 0%, #f2f2f2 42%, #e8e8e8 100%); /* FF3.6-15 */
    // background: -webkit-linear-gradient(left,  #e0e0e0 0%,#f2f2f2 42%,#e8e8e8 100%); /* Chrome10-25,Safari5.1-6 */
    // background: linear-gradient(to right,  #e0e0e0 0%,#f2f2f2 42%,#e8e8e8 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    // filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e0e0e0', endColorstr='#e8e8e8',GradientType=1 ); /* IE6-9 */
    
    background: transparent;
    
    color: $navbarContact-color;
    
    overflow: hidden;
    
    min-height: 100px;
    .tpl-home & {
        height: 187px;
    }
    
    .NavbarContactWrp {
        text-align: right;
        padding-left: 15px;
        padding-right: 15px;
        min-height: 100px;
        .tpl-home & {
            height: 187px;
        }
        
        display: flex;                      // display:         flex | inline-flex;
        flex-direction: row;                // flex-direction:  row | row-reverse | column | column-reverse;
        flex-wrap: nowrap;                  // flex-wrap:       nowrap | wrap | wrap-reverse;
        align-items: center;         // align-items:     flex-start | flex-end | center | baseline | stretch;
        align-content: center;              // align-content:   flex-start | flex-end | center | space-between | space-around | stretch;
        justify-content: flex-end;     // justify-content: flex-start | flex-end | center | space-between | space-around;
        
        @media (max-width: $screen-xs-max) {
            // justify-content: center;
            flex-wrap: wrap;
        }
        
        @media (max-width: $screen-xxs-max) {
            justify-content: center;
        }
    }
    
    .NavbarContactImg {
        display: inline-block;
        vertical-align: middle;
        // margin-left: 15px;
        // margin-right: 15px;
        
        flex: 0 0 65px;
        width: 65px;
        
        .tpl-home & {
            
            width: 87px;
            flex: 0 0 87px;
        }
        
    }
    
    .NavbarContactList {
        display: inline-block;
        vertical-align: middle;
        margin-left: 15px;
        margin-right: 15px;
        flex: 0 0 auto;
        
        .Item-Row {
            
            .Item-Key {
                fsize: 11px;
                .tpl-home & {
                    fsize: 14px;
                }
            }
            .Item-Val {
                font-size: 14px;
                .tpl-home & {
                    font-size: 18px;
                }
            }
            
        }
        
    }

    // font-family: $font-2;
    // font-weight: 700;
    // font-size: 16px;
    // @media (max-width: $screen-xs-min) {
    //     font-size: 14px;
    // }
    
    // .item {
    //     margin-left: 10px;
    //     float: right;
    // }
    
    // a {
    //     color: $navbarContact-link-color;
    //     &:hover,
    //     &:focus {
    //         color: $navbarContact-link-color-hover;
    //     }
    // }
    
    // .glyph {
    //     background: $color-white;
    //     border-radius: 50%;
    //     font-size: 11px;
    //     padding: 5px;
    //     vertical-align: text-top;
    // }
}
