$footer-bg-color: transparent;
$footer-color: $color-black;
$footer-color-link: $color-black;
$footer-color-link-hover: $color-3;

.Footer {    
    @include clearfix;
    margin-top: 70px;
    // min-height: 190px;
    border-top: 0 solid $navbarMain-bg-color;
    // background-color: $footer-bg-color;
    background-color: transparent;
    color: $footer-color;
    padding: 20px 0 0 0;
    
    > .container {
        background-color: $footer-bg-color;
    }
    
    a {
        color: $footer-color-link;
        transition-property: color, background-color;
        transition-duration: .5s;
        &:hover,
        &:focus {
            color: $footer-color-link-hover;
            background-color: transparent;
        }
    }
    
    section {
        @include clearfix;
        margin: 20px 0;
        font-family: $font-1;
        font-size: 14px;
        font-weight: 400;
    }
    
    .address {
        address {
            margin-bottom: 5px;
        }
    }
    
    p {
        margin: 0;
    }
    
    .footer-slogan {
        padding-top: 30px;
    }
}

.FooterWide {
    background-color: $color-black;
    min-height: 56px;
}

.FooterAddress {
    margin-top: 0;
}

.FooterAddressList {
    text-align: center;
    
    font-size: 14px;
    
    .Item-Key {
        text-transform: uppercase;
    }
    
    .Item-Val {
        font-size: 18px;
    }
    
    @media (min-width: $screen-sm) {
        display: flex;                      // display:         flex | inline-flex;
        flex-direction: row;                // flex-direction:  row | row-reverse | column | column-reverse;
        flex-wrap: nowrap;                    // flex-wrap:       nowrap | wrap | wrap-reverse;
        align-items: center;         // align-items:     flex-start | flex-end | center | baseline | stretch;
        align-content: center;              // align-content:   flex-start | flex-end | center | space-between | space-around | stretch;
        justify-content: center;     // justify-content: flex-start | flex-end | center | space-between | space-around;
    
        .sec {
            order: 0;
            align-self: auto;               // align-self:      auto | flex-start | flex-end | center | baseline | stretch;
            flex: 1;                     // flex:            none | [ <'flex-grow'> <'flex-shrink'>? || <'flex-basis'> ];
        }
        
        .sec1 {
            text-align: right;
        }
        .sec2 {
            flex: 0 0 87px;
        }
        .sec3 {
            text-align: left;
        }
    }
    
    
    .sec2 {
        margin: 5px 20px;
    }
}

.FooterInfo {
    font-family: $font-1;
    font-size: 14px;
    font-weight: 400;
    text-transform: uppercase;
    color: $color-3;
    a {
        color: inherit;
        &:hover,
        &:focus {
            color: $color-white;
        }
    }
    
    width: 100%;
    text-align: center;
    .FooterInfo-cnt {
        display: inline;
        display: flex;
        justify-content: center;
    }
    
    .siteName {
        text-transform: uppercase;
    }
    
}