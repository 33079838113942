body {
    overflow-x: hidden !important;
    position: relative;
    font-family: $font-1;
    // padding-top: 20%;
    padding-bottom: 0;
    color: $color-black;
    
    background-color: $color-3;
    background-position: center 56px;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-attachment: scroll;
    background-image: url('../img/bg/bg-page.jpg');
    &.tpl-home {
        background-image: url('../img/bg/bg-main.jpg');
    }
}

header {
    @include clearfix;
    position: relative;
    // background-color: #FFE1E1;
}

footer {
    @include clearfix;
    position: relative;
}

.main {
    @include clearfix;
    position: relative;
    
    margin-top: 15px;
    margin-bottom: 15px;
    
    @media (min-width: $screen-md-min) {
        margin-top: -68px;
        margin-bottom: 15px;
    }
    
    .tpl-home & {
        margin-top: 15px;
        margin-bottom: 15px;
    }
    
    // background-color: #FFF6F6;
}

.Left {
    @extend .col-xs-12, .col-sm-12, .col-md-7;
    // background-color: #CBFFEB;
    
    @media (min-width: $screen-md-min) {
        width: 55%;
        padding-right: 3%;
    }
}

.Right {
    @extend .col-xs-12, .col-sm-12, .col-md-5;
    @media (min-width: $screen-md-min) {
        width: 45%;
    }
    // background-color: #FFF9DA;
    // padding-top: 50px;
}

// header .container, footer .container {

//     @media (min-width: $screen-sm-min) {
//         width: $container-sm + 12px;
//         padding-left: 0;
//         padding-right: 0;
//     }
//     @media (min-width: $screen-md-min) {
//         width: $container-md + 12px;
//         padding-left: 0;
//         padding-right: 0;
//     }
//     @media (min-width: $screen-lg-min) {
//         width: $container-lg + 12px;
//         padding-left: 0;
//         padding-right: 0;
//     }
// }

// .col-fixed-right {

//     // background-color: #F3F3F3;
 
//     margin-top: 15px;
 
//     section {        
//         @include clearfix;
//         width: 286px;
//         @media (max-width: $screen-sm-max) {
//             width: 100%;
//         }
//         // background-color: red;
//         float: right;
//     }
// }