.RightCalendar {
    margin-bottom: 45px;
    border-left: 8px solid $color-2;
    
        .title {
        font-family: $font-2;
        text-transform: uppercase;
        color: $color-black;
        font-size: 26px;
        padding-left: 15px;
    }
}

@import 'calendar2bootstrap';

#Calendar {

    .calendar {
        border: none !important;
        position: relative;
        border-collapse: separate;
        border-spacing: 1px;
        
        .dow {
            font-weight: 400;
            font-size: 12px;
            padding-left: 7px;
        }
        
        .weekend {
            color: #FF665E !important;
        }
        
        .today {
            font-weight: 400;
        }
        
        .isevent {
            background-color: lighten($color-2, 20%);
            // &.oldevent {
            //     background-color: transparent;
            // }
        }
        
        .cell {
            padding: 0 2px 0 0;
            font-weight: 400;
            
            &:hover {
                opacity: 1;
                background-color: $color-2;
            }
        }
        
    }

    .date {
        width: 30px !important;
        height: 30px !important;
    }
    
    .event {
        min-height: 50px;
        min-width: 285px;
        max-height: 400px;
        max-width: 285px;
        left: -37px;
    }
    
    .num {
        display: none;
    }
    
    .eventdate {
        display: block;
        font-weight: 700;
    }
     
    .link {
        display: block;
        font-weight: 700;
        margin-top: 5px;
        margin-bottom: 5px;
    }
     
    .notice {
        display: block;
        margin-bottom: 20px;
    }
    
}
