.cookieInfo {
    
    position: fixed;
    z-index: 9999;
    bottom: 0;
    left: 0;
    right: 0;
    
    background-color: #D9D9D9;
    border-top: 2px solid #B9B9B9;
    
    > .container{
        > .cookieInfoInner {
            
            padding: 20px 0 40px 0;
            
            > .cookieInfoClose {
                float: right;
                margin-bottom: 20px;
                margin-left: 20px;
                border: none;
                > span {
                    @extend .glyphicon, .glyphicon-remove;
                }
                &:hover,
                &:focus {
                    color: #FF0000;
                }
            }
        }
    }
}