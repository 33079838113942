@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,400italic,700,700italic&subset=latin,latin-ext');
@import url('https://fonts.googleapis.com/css?family=Open+Sans+Condensed:300,700,300italic&subset=latin,latin-ext');
// @import url(https://fonts.googleapis.com/css?family=Oswald:400,700&subset=latin,latin-ext);
// @import url('http://fonts.googleapis.com/css?family=Comfortaa:400,700&subset=latin,latin-ext');
// @import url('https://fonts.googleapis.com/css?family=Exo:400,400italic,700,700italic&subset=latin,latin-ext');

@import 'variables/vars-pre';

@import 'variables/vars-bootstrap';

// @import 'compass';

// if all bootstrap into screen.css
@import '../lib/bootstrap-sass/assets/stylesheets/bootstrap';

// if bootstraps variables and mixins
// @import '../lib/bootstrap-sass/assets/stylesheets/bootstrap/variables';
// @import '../lib/bootstrap-sass/assets/stylesheets/bootstrap/mixins';

@import '../lib/font-awesome/scss/font-awesome';

@import 'variables/vars-post';

@import 'mixins/mixin-opacity';

// UTILITIES ----------------------------------------------------------------------------------------------------------
@import 'utils/classes';
@import 'utils/embed-responsive';
@import 'utils/moz-focus';
@import 'utils/table-responsive';

// SCAFFOLDING --------------------------------------------------------------------------------------------------------
@import 'scaffolding/blocks';
@import 'scaffolding/typography';
@import 'scaffolding/links';
@import 'scaffolding/dropdown-menu';
@import 'scaffolding/forms';
@import 'scaffolding/grid';
@import 'scaffolding/imgs';

// OVERRIDES ----------------------------------------------------------------------------------------------------------
@import 'overrides/awesome-bootstrap-checkbox.scss';
@import 'overrides/bootstrap-select';

// ELEMENTS -----------------------------------------------------------------------------------------------------------
@import 'elements/big-loader';
@import 'elements/btns';
@import 'elements/carousel';
@import 'elements/modal';
@import 'elements/scroll-top';

@import 'elements/cookieInfo';

@import 'elements/specialLink';

// COMPONENTS ---------------------------------------------------------------------------------------------------------
@import 'components/Banners/banners';

@import 'components/Navbars/NavbarContact';
@import 'components/Navbars/NavbarMain';

@import 'components/Breadcrumbs/Breadcrumbs';

@import 'components/Pagination/Pagination';

@import 'components/Download/Download';

@import 'components/PhotoLinks/photo-links';
@import 'components/PhotoLinks/photo-links-article';
@import 'components/PhotoLinks/photo-links-company';
@import 'components/PhotoLinks/photo-links-recommend';
@import 'components/PhotoLinks/photo-links-partners';

@import 'components/Footer/Footer';
@import 'components/Footer/FooterMenu';

@import 'components/Galleries/gallery-single';
@import 'components/Galleries/gallery-1';
@import 'components/Galleries/gallery-2';
@import 'components/Galleries/GalleryTv';
@import 'components/Galleries/GalleryAlbums';
@import 'components/Galleries/GalleryAlbum';

@import 'components/bx';
@import 'components/lightSlider';
@import 'components/tables';

@import 'components/News/NewsHome';
@import 'components/News/NewsPostList';
@import 'components/News/NewsPost';

@import 'components/Right/RightCalendar';
@import 'components/Right/RightTrainings';
@import 'components/Right/RightInfos';

// @import 'components/Forms/TrainingSuggestForm';
// @import 'components/Forms/TrainingApplyForm';
@import 'components/Forms/Form1';
@import 'components/Forms/Form2';
@import 'components/Forms/FormContact1';
@import 'components/Forms/FormsList';

@import 'components/Tables/Table';

@import 'components/Address/AddressCard';


@import 'components/Offers/Offer/offer-list';
@import 'components/Offers/Offer/HomeOffer';
@import 'components/Offers/Offer/HomeOfferDownload';

@import 'components/Offers/Trainings/TrainingsHome';
@import 'components/Offers/Trainings/Trainings';
@import 'components/Offers/Trainings/Training';

@import 'components/Offers/Projects/Projects';
@import 'components/Offers/Projects/Project';

@import 'components/Offers/Coms/Coms';
@import 'components/Offers/Coms/Com';
