.Coms {
    
}

.ComsLetters {
    text-align: center;
    margin-top: 30px;
    display: block;
    list-style-type: none;
    
    .Letter {
        display: inline;
        font-size: 14px;
        &:after {
            content: " | ";
        }
        &:last-child:after {
            content: "";
        }
    }
}

.ComsList {
    margin-top: 30px;
    // background-color: #FFDEDE;
}

.ComsListList {
    list-style-type: none;
    
    margin: 0 auto;
    text-align: center;
    // background-color: #DFFFE4;
    
    // display: flex;
    // flex-wrap: wrap;
    // justify-content: flex-start;
}

.ComsListItem {
    display: inline-block;
    width: 292px;
    max-width: 100%;
    margin-bottom: 60px;
    margin-left: 15px;
    margin-right: 15px;
    vertical-align: top;
    
    @media (max-width: $screen-xxs-max) {
        margin-left: 0;
        margin-right: 0;
    }
    // background-color: #FFF0DF;
}

.ComsListItemLink {
    
    .ComsThumb {
        background-color: #819aad;
        padding: 9px 6px;
        img {
            max-width: 100%;
            display: block;
        }
    }
            
    .ComsData {
        
        text-align: left;
        padding: 9px 6px;
               
        font-size: 14px;
        
        .Title {
            font-size: 24px;
            text-transform: uppercase;
            margin-bottom: 10px;
        }
        .Address {
            
        }
        .Surface {
            
        }
        .Locals {
            
        }
        .Key {
            
        }
        .Val {
            
        }
    }
    
}