.banner-wrp {
    position: relative;
}

.banner-home-wrp, .banner-start-wrp {
    @include clearfix;
    position: relative;
}

.banner-home-wrp {
    // border-bottom: 1px solid $navbar-default-border;
}

.banner-start-wrp {
    border-top: 1px solid $navbar-default-border;
    border-bottom: 1px solid $navbar-default-border;
}

.banner-home,.banner-start {

    position: relative;
    // max-width: 1200px;
    margin: 0 auto;
    
    .carousel-indicators {
            display: none;
        li {
        }
        
        .active {
        }
    }
    
    .carousel-inner {
        > .item {
            > img, a > img {
                    width: 100%;
            }
            > .carousel-caption {
                display: none;
                // to display pagetitle
                // display: block;
                // text-align: left;
                // text-transform: uppercase;
                // @include text-shadow(none);
                
                // left: 0;
                // right: 0;
                
                // @media (max-width: $grid-float-breakpoint-max) {
                //     bottom: 0;
                // }
                
                // .caption-front {
                //     position: absolute;
                //     display: block;
                //     z-index: 21;
                //     font-family: $font-2;
                //     font-size: 20px;
                //     color: $color-link-1;
                //     left: 0;
                //     bottom: -15px;
                // }
                // .caption-back {
                //     position: absolute;
                //     bottom: -25px;
                //     left: -2px;
                //     display: block;
                //     z-index: 20;
                //     font-family: $font-2;
                //     font-size: 57px;
                //     font-weight: 700;
                //     color: rgba($color-link-1, 0.11);
                // }
            }
        }
    }

    .carousel-control {
        display: none;
    }
}

// ::: banner-page
.banner-page-wrp {
    @include clearfix;
    position: relative;
    display: none !important;
}
.banner-page {
    
    position: relative;
    // max-width: 1200px;
    margin: 0 auto;
    
    .carousel-indicators {
            display: none;
        li {
        }
        
        .active {
        }
    }
    
    .carousel-inner {
        > .item {
            > img, a > img {
                width: 100%;
            }
            > .carousel-caption {
                display: none;
            }
        }
    }

    .carousel-control {
        display: none;
    }
}

.banner-page {
    
    position: relative;
    // max-width: 1200px;
    margin: 0 auto;

    .carousel-indicators {
        display: none;
        li {
        }

        .active {
        }
    }
    
    .carousel-inner {
        > .item {
            > img, a > img {
                width: 100%;
            }
            > .carousel-caption {
                // display: block;
                // text-align: left;
                // text-transform: uppercase;
                // text-shadow: none;
                
                // left: 0;
                // right: 0;
                
                // @media (max-width: $grid-float-breakpoint-max) {
                //     bottom: 0;
                // }
                
                // .caption-front {
                //     position: absolute;
                //     display: block;
                //     z-index: 21;
                //     font-family: $font-2;
                //     font-size: 20px;
                //     color: $color-link-1;
                //     left: 0;
                //     bottom: -15px;
                // }
                // .caption-back {
                //     position: absolute;
                //     bottom: -25px;
                //     left: -2px;
                //     display: block;
                //     z-index: 20;
                //     font-family: $font-2;
                //     font-size: 57px;
                //     font-weight: 700;
                //     color: rgba($color-link-1, 0.11);
                // }
            }
        }
    }

    .carousel-control {
        display: none;
    }
}
