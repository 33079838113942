.gallery-single-wrp {
    @include clearfix;
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: $screen-xs-max) {
        max-width: 263px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.gallery-single-wrp.gallery-single-wrp-thumbnails {
    @media (max-width: $screen-xs-max) {
        max-width: none !important;
    }
}

.gallery-single {
    
    
    .gallery-single-photo-wrp {
        background-color: $color-2;
        border: 1px solid $color-2;
        
        float:left;
     
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            max-width: 227px !important;
        }
        
        
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            max-width: 233px !important;
        }
        
        @media (max-width: $screen-xs-max) {
            max-width: 263px !important;
        }
    }
    
    
    .gallery-single-photo {
        // border: none;
        // padding: 0;
        // margin: 0 5px 5px 5px;
        // float: left;        
        
        
        // @include clearfix;
        margin: 1px;
    

        
        a {
            display: block;
            max-width: 100%;
            
            
            transition-property: opacity;
            transition-duration: 0.5s;
            &:hover,
            &:focus {
                @include opacity(.8);
            }
            
            img {
                
                @include img-responsive;
                
                // max-width: 369px;
                // height: auto;
                
                // @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                //     max-width: 303px;
                //     height: auto;
                // }
                // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                //     max-width: 229px;
                //     height: auto;
                // }
                // @media (max-width: $screen-xs-max) {
                //     max-width: 100%;
                //     height: auto;
                // }
            }
        }
    }
    
}

.gallery-single-thumbnails {
    
    @include clearfix;
    
    .gallery-single-photo-wrp {
        
        background-color: transparent;
        margin: 5px;
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            max-width: 23.9% !important;            
        }
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            max-width: 23.6% !important;
        }
        
        @media (max-width: $screen-xs-max) {
            max-width: 31% !important;
            margin: 1%;
        }
        
    }

}
