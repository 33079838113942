$footerMenu-font-size: 18px;
$footerMenu-font-weight: 400;
$footerMenu-font-family: $font-1;

$footerMenu-color-link: $color-black;
$footerMenu-color-link-active: $color-white;
$footerMenu-color-link-hover: $color-white;

.FooterMenu {
    
    border-top: 1px solid $color-black;
    padding: 20px 0 0 0;
    margin-bottom: 0;
    
    > .navbar-nav {
        @inlude clearfix;
        margin: 0;
        padding: 0;
        list-style-type: none;
        
        display: block;
        text-align: center;
        
        @media (min-width: $screen-xs-min) {
            display: flex;                      // display:         flex | inline-flex;
            flex-direction: row;                // flex-direction:  row | row-reverse | column | column-reverse;
            flex-wrap: wrap;                    // flex-wrap:       nowrap | wrap | wrap-reverse;
            align-items: center;         // align-items:     flex-start | flex-end | center | baseline | stretch;
            align-content: center;              // align-content:   flex-start | flex-end | center | space-between | space-around | stretch;
            justify-content: center;     // justify-content: flex-start | flex-end | center | space-between | space-around;
        }
        
        float: none;

        text-transform: uppercase;
        font-size: $footerMenu-font-size;
        font-weight: $footerMenu-font-weight;
        font-family: $footerMenu-font-family;
        
        > li {
            // display: inline-block;
            float: none;
            text-align: center;
            white-space: nowrap;
            
            margin: 5px 0;
            
            
            @media (min-width: $screen-xs-min) {
                margin-left: 17px;
                margin-right: 17px;
                flex: 0 0 auto;
            }
            
            &:last-child {
                // margin-right: 0;
            }
            
            > a {
                padding: 0;
                color: $footerMenu-color-link;
                
                &:hover,
                &:focus {
                    color: $footerMenu-color-link-hover;
                    background-color: transparent;
                }
            }
            &.active > a {
                color: $footerMenu-color-link-active;
                
                &:hover,
                &:focus {
                    color: $footerMenu-color-link-hover;
                    background-color: transparent;
                }
            }
        }
    }
}

.Footer section.FooterMenu {
    // margin-top: 0;
}

.FooterMenuOffer {
    @extend .FooterMenu;
    > ul {
        > li {
            text-transform: none;
        }
    }
}