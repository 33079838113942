.FormContact {
    @include clearfix;
    margin-top: 0;
    
    // background-color: #eeeeee;
    // padding: 25px;
    
    .title {
        font-size: 14px;
        font-family: $font-1;
        // text-transform: uppercase;
        color: $color-black;
        margin-bottom: 30px;
    }
    
    // .TrainingData {
    //     box-shadow: none;
    //     margin-bottom: 0;
        
    //     .val.subject {
    //         text-transform: uppercase;
    //     }
    // }
}

.FormContactForm {
    .BtnSubmit {
        // float: right;
        margin-top: 20px;
        font-size: 24px;
        padding: 18px 22px 19px 80px;
        width: 254px;
        height: 92px;
        background-image: url('../img/btn/btn-send.png');
        display: block;
        margin: 50px 0 0 auto;
    }
    
    textarea {
        height: 211px;
    }
    
    // .title {
    //     font-size: 18px;
    //     font-family: $font-2;
    //     text-transform: uppercase;
    //     color: $color-black;
    //     font-weight: 700;
    //     margin-top: 10px;
    //     margin-bottom: 10px;
    // }
    
    // .section {
    //     margin-top: 30px;
    // }
    
    .checkbox {
        label {
            font-weight: 400;
            font-family: $font-1;
            font-size: 14px;
        }
    }
    
    .GroupAddress {
        .box-wrp {
            @include clearfix;
            margin: 0 -0.3%;
            label {
                white-space: nowrap;
            }
        }
        .box-inline {
            display: inline-block;
            // background-color: #FEDEDE;
            &.invisible-wrp {
                * {
                    visibility: hidden;
                }
            }
        }
        .box1 {
            width: 48.2%;
            float: left;
            margin: 0 0.3%;
        }
        .box2 {
            width: 25%;
            float: left;
            margin: 0 0.3%;
        }
        .box3 {
            width: 25%;
            float: left;
            margin: 0 0.3%;
        }
    }
    
    .GroupDate {
        .box-wrp {
            @include clearfix;
            margin: 0 -0.3%;
            label {
                white-space: nowrap;
            }
        }
        .box-inline {
            display: inline-block;
            // background-color: #FEDEDE;
            &.invisible-wrp {
                * {
                    visibility: hidden;
                }
            }
        }
        .box1 {
            width: 21.2%;
            float: left;
            margin: 0 0.3%;
            position: relative;
        }
        .box2 {
            width: 47%;
            float: left;
            margin: 0 0.3%;
        }
        .box3 {
            width: 30%;
            float: left;
            margin: 0 0.3%;
        }
        
    }
    
    .Cols {
        @extend .row;
        
        .Col1 {
            @extend .col-xs-12, .col-sm-6;
        }
        
        .Col2 {
            @extend .col-xs-12, .col-sm-6;
        }
        
        // .Col3 {
        //     @extend .col-xs-12, .col-sm-5;
        // }
        
    }
}
