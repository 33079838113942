.photo-links-article {
    @include clearfix;
    position: relative;
    z-index: 500;
    margin-top: 60px;
    margin-bottom: 20px;
    // &.on-home {        
    //     @media (min-width: $screen-xs-min) {
    //       top: -55px;
    //       margin-bottom: -55px;
    //       margin-top: 0;
    //     }
    // }
    
    // > .container {
    //     > .row {
    //         @media (min-width: $screen-md-min) {
    //             > .photo-links-item-wrp:nth-of-type(1),
    //             > .photo-links-item-wrp:nth-of-type(4) {
    //                 padding-left: 15px;
    //                 padding-right: 0;
    //                 // background-color: red;
    //             }
    //             > .photo-links-item-wrp:nth-of-type(2),
    //             > .photo-links-item-wrp:nth-of-type(5) {
    //                 padding-right: 15px;
    //                 padding-left: 0;
    //                 // background-color: green;
    //             }
    //             > .photo-links-item-wrp:nth-of-type(4),
    //             > .photo-links-item-wrp:nth-of-type(5) {
    //                 .photo-links-item {
    //                     top: -1px;
    //                 }
    //             }
    //         }
    //     }
    // }
    
    img {
        @include img-responsive;
    }
    .photo-links-item-wrp {
        // margin: 0 !important;
        // padding: 0 !important;
        &:last-child {
          .photo-links-item {
            &:last-child:after {
              display: none;
            }
          }
        }
        
        .photo-links-item {
            
            // @include clearfix;
            position: relative;
            display: block;
            max-width: none;
            margin: 0 auto 0 auto;
            margin-top: 0;
            
            @media (max-width: $screen-sm-max) {
                margin-bottom: 20px;
            }
            
            // > .row {
            //     @media (min-width: $screen-md-min) {
                    
            //         > div {
            //             &:first-child {
            //                 padding-left: 15px;
            //                 padding-right: 0;
            //             }
            //             &:last-child {
            //                 padding-left: 0;
            //                 padding-right: 15px;
            //             }
            //         }
                    
            //         > .pull-push {
            //             &:first-child {
            //                 padding-right: 15px;
            //                 padding-left: 0;
            //             }
            //             &:last-child {
            //                 padding-right: 0;
            //                 padding-left: 15px;
            //             }
            //         }
                
            //     }
            // }
            
            .pull-push {
                @media (min-width: $screen-md-min) {
                    float: right;
                }
            }
            
            &:after {
              display: none;
              content: "\f054";
              font-family: FontAwesome;
              position: absolute;
              right: -10%;
              top: 21%;
              font-size: 40px;
              color: $color-2;
                
                @media (max-width: $screen-sm-max) {
                    display: none;
                }
            }
            
            .image-link {
                @include clearfix;
                // border-radius: 50%;
                display: block;
                width: 100%;
                height: auto;
                // max-width: 276px;
                max-width: none;
                padding-bottom: 0;
                // padding-bottom: 100%;
                // max-height: 276px;
                padding: 0;
                border: 0 solid $color-gray;
                margin: 0 auto;
                // background-image: url("/templates/default/img/bg/round-placeholder.png");
                // background-position: center top;
                // background-attachment: scroll;
                // background-repeat: no-repeat;
                // background-size: 100% auto;
                position: relative;
                background-color: transparent;
                // background-color: $color-black;
                > img {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 0;
                    transform: translate3d(-50%, -50%, 0);
                    transition-property: opacity;
                    transition-duration: 1s;
                    @include img-responsive;
                    border-radius: 0;
                    display: block;
                    // width: 94%;
                    &.image-0 {
                        position: static;
                        @include opacity(0);
                    }
                    &.image-2 {
                        @include opacity(0);
                    }
                }
                &:hover {
                    > .image-1 {
                        @include opacity(0);
                    }
                    > .image-2 {
                        @include opacity(1);
                    }
                }
            }
            
            .title,
            .title > a {
                display: block;
                @media (min-width: $screen-md-min) {
                    margin: 0 0 20px 0;                    
                }
                padding: 0;
                font-weight: 400;
                font-family: $font-2;
                font-size: 17px;
                text-align: left;
                text-transform: uppercase;
                text-decoration: none;
                color: $color-black;
                transition-property: color;
                transition-duration: 0.5s;
                
                font-weight: 400;
                font-size: 17px;
                text-transform: uppercase;
                
                &:hover,
                &:focus {
                    color: $color-link-2;
                }
                &.title-2 {
                    margin-top: 0;
                    font-size: 16px;
                }
            }
            
            .title {
                position: static;
                @include clearfix;
                top: 0;
                margin-bottom: 0;
                margin-top: 19px;
                
                @media (max-width: $screen-md-max) {
                    margin-top: 8px;
                }
                
                @media (min-width: $screen-md-min) {
                    margin-left:15px;
                    margin-right:15px;
                }
            }
            
            .description {
                margin: 0 0 0 0;
                padding: 0;
                font-family: $font-1;
                text-align: left;
                font-size: 14px;
                line-height: 1.6;
                @media (max-width: $screen-md-max) {
                    line-height: 1.3;
                }
                @media (min-width: $screen-md-min) {
                    margin-left:15px;
                    margin-right:15px;
                }
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li:before {
                        content: "-";
                        position: relative;
                        left: -5px;
                    }
                    li {
                        text-indent: -5px;
                        @media (max-width: $screen-sm-max) {
                            text-align: center;
                        }
                    }
                }
            }
            .arrow-link {
                display: block;
                margin: 22px auto;
                width: auto;
                text-align: center;
                transition-property: opacity;
                transition-duration: 1s;
                &:hover {
                    @include opacity(0.7);
                }
                img {
                    display: inline-block;
                }
            }
        }
    }
}
