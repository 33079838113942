.RightInfos {
    margin-bottom: 45px;
    border-left: 8px solid $color-gray;
    // min-height: 270px;
    
    background-position: right center;
    background-image: url('/templates/default/img/bg/bg-right-infos.jpg');
    background-repeat: no-repeat;
    
    .title {
        font-family: $font-2;
        text-transform: uppercase;
        color: $color-black;
        font-size: 26px;
        padding-left: 15px;
    }
    
}

.RightInfoList {
    
    list-style-type: none;
    
    li {
        
        margin: 18px 16px;
        
        .line1 {
            font-size: 14px;
            font-family: $font-1;
        }
        
        .line2 {
    
            .val {
                font-family: $font-2;
                font-weight: 700;
                font-size: 18px;
                text-transform: uppercase;
                color: $color-2;
            }
    
            .key{
                font-family: $font-2;
                font-weight: 700;
                font-size: 18px;
                text-transform: uppercase;
                color: $color-black;
            }
        }
    }
}
        