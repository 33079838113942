.TrainingsHome {
    
}


.TrainingsHomeList {
    margin-top: 15px;
    list-style-type: none;
}


.TrainingsHomeListItem {
    
    margin-bottom: 20px;
    
    .title {
        margin: 0;
        padding: 0;
        > a {
            font-family: $font-2;
            // color: $color-2;
            text-transform: uppercase;
            font-size: 18px;
            font-weight: 700;
        }
    }
    
    .key {
        font-family: $font-2;
        font-size: 16px;
        font-weight: 700;
        display: inline-block;
    }
    
    .val {
        font-family: $font-1;
        font-size: 14px;
        font-weight: 400;
        display: inline-block;
    }
    
}