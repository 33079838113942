.moreLink {
    // position: absolute;
    // right: 15px;
    // top: 40px;
    font-size: 12px;
    text-transform: uppercase;
    
    span {
        position: relative;
        &:after {
            content: "";
            display: block;
            background-color: $color-black;
            width: 7px;
            height: 7px;
            position: absolute;
            right: -13px;
            top: 5px;
            border-radius: 50%;
        }
    }    
}

.backLink {
    @extend .moreLink;
    // margin-bottom: 20px;
    // font-size: 12px;
    
    // @media (min-width: $screen-xs-max) {
    //     position: relative;
    //     z-index: 110;
    //     top: -48px;
    //     margin-bottom: -48px;
    //     float: right;
    // }
    
    // @media (min-width: $screen-sm-min) {        
    //     text-align: right;
    // }
    
    // font-family: $font-2;
    // color: $color-2;
    // text-transform: uppercase;
    // font-weight: 700;
}
