.RightTrainings {
    margin-bottom: 45px;
    border-left: 8px solid $color-gray;
    
    
    .TrainingSuggest {
        @include clearfix;
        margin-top: 0;
        
        background-color: transparent;
        padding: 0 15px 0 15px;
        
        .title {
            font-family: $font-2;
            text-transform: uppercase;
            color: $color-black;
            font-size: 26px;
            // padding-left: 15px;
        }
    }

    .TrainingSuggestForm {
        .BtnSubmit {
            float: right;
            margin-top: 20px;
        }
        
        .form-control {
            background-color: $color-white;
        }
        
        textarea {
            height: 107px;
        }
        
        .Cols {
            @extend .row;
        }
        
        .Col1 {
            @extend .col-xs-12;
        }
        
        .Col2 {
            @extend .col-xs-12;
        }
        
        .Col3 {
            @extend .col-xs-12;
        }
    }
    
    
}