.form-group {
    margin-bottom: 10px;
    label, .form-text {
        color: $color-black;
        font-size: 14px;
        font-family: $font-1;
        font-weight: 400;
    }
    
    &.adres {
        // hidden field to cheat bots
        display: none;
    }
    
}

.form-control {
    border-radius: 0;
    color: $color-black;
    background-color: #aac7df;
    border-width: 1px;
    border-color: $color-black;
    height: 47px;
    font-size: 14px;
    
    &:focus {
      border-color: darken($color-3, 20%);
    }

    &::placeholder {
        color: rgba($color-2, 0.4);
    }
    
    option {
        // background-color: $color-bg-1;
    }
}
    
textarea.form-control {        
    height: auto;
    min-height: 86px;
    resize: vertical;
}

.contact-form,
.escort-form {    
    textarea {
        min-height: 141px;
    }
    
    
    // .btn-1 {
    //     display: block;
    //     width: 100%;
    //     font-family: $font-2;
    //     text-decoration: none;
    //     text-transform: uppercase;
    //     color: $color-white;
    //     font-size: 16px;
    //     font-weight: 400;
    //     background-color: $color-2;
    //     transition-duration: 0.6s;
    //     transition-property: background-color;
    //     border-radius: 0;
    //     &:focus,&:active {
    //         @extend .btn-1;
    //     }
    //     &:hover {
    //         background-color: $color-bg-2;
    //         color: $color-black;
    //     }
    // }
}

.newsletter-form {
    max-width: 300px;
    .caption {
        color: $color-2;
    }
    .form-group {
        width: 73%;
        float: left;
        + .form-group {
            width: 25%;
            float: right;
        }
    }
    .btn-1 {
        line-height: 1;
    }
}

.radio-inline,
.checkbox-inline {
    margin-right: 10px;
}
.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
  margin-top: 0;
  margin-left: 0; // space out consecutive inline controls
}