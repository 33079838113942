.tpl-news {
    .sectionTitle {
        margin-top: 20px;
        padding-bottom: 10px;
        width: 100%;
        &:before {
            background-image: url('../img/glyphs/glyph-aktualnosci.png');
        }
    }
}


.NewsPostList {
    
    @include clearfix;
    margin-top: 20px;
    
}

.NewsPostListItem {
    
    margin-bottom: 15px;
    
    .moreLink {
        float: right;
        margin-right: 20px;
    }
}

.NewsPostListItemData {
    
    @include clearfix;
    position: relative;
    top: 0;
    border-bottom: 1px solid rgba($color-gray, .5);
    padding-bottom: 10px;
    
    .NewsPostListItem:last-of-type & {
        border-bottom: none;
    }
    
    .image {
        display: none;
        position: relative;
        top: 10px;
        float: left;
        max-width: 200px;
        margin-right: 20px;
        margin-bottom: 17px;
    }
    
    .title {
        margin: 0 0 15px 0;
        padding: 0;
        
        line-height: 0.7;
        
        padding-left: 40px;
        
        > a {
            font-family: $font-1;
            // color: $color-2;
            text-transform: none;
            font-size: 16px;
            font-weight: 700;
        }
    }
    
    .date {
        position: absolute;
        top: 0;
        margin: 0 0 0 0;
        font-family: $font-1;
        font-size: 10px;
        
        .date-d, .date-m  {
            display: block;
            margin: 0 auto;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
        }
        .date-d {
            background-color: $color-black;
            color: $color-3;
            border-radius: 50%;
            width: 29px;
            height: 29px;
            padding: 4px;
        }
        .date-m {
            font-size: 12px;
            text-transform: uppercase;
            margin-top: 3px;
        }
        .date-y {
            display: none;
        }
    }
    
    .entry {
        padding-left: 40px;
        
        font-family: $font-1;
        font-size: 14px;
        // line-height: 1.2;
        margin: 0 0 15px 0;
        p {
            font-size: 14px;
            margin: 0;
            padding: 0;
        }
    }
    
}