.HomeOfferDownload {
    @include clearfix;
    // background-color: #FFE4E4;
    
    .sectionTitle {
        margin-top: 20px;
        padding-bottom: 10px;
        width: 100%;
        &:before {
            background-image: url('../img/glyphs/glyph-druki.png');
        }
    }
}

.HomeOfferDowloadList {
    list-style-position: outside;
    margin-left: 30px;
    padding-left: 0;
    
    li {
        a {
            color: $color-black;
            &:hover,
            &:focus {
                color: $color-white;
            }
        }
    }
}