.NewsHome {
    @include clearfix;
    position: relative;
        
    
    .sectionTitle {
        margin-top: 20px;
        padding-bottom: 10px;
        width: 100%;
        &:before {
            background-image: url('../img/glyphs/glyph-aktualnosci.png');
        }
    }
    
    // background-color: #eeeeee;
    // margin-left: -25px;
    // margin-right: -25px;
    // padding: 25px;
    
    // h1 {
    //     margin-bottom: 25px;
    // }
    
    .moreLink {
        position: absolute;
        right: 15px;
        top: 40px;
    }
    
}


.NewsHomeList {
    position: relative;
    top: -9px;
    margin-top: 0;
    list-style-type: none;
    // display: flex;
    // justify-content: space-between;
    li {
        margin-bottom: 40px;
        @media (min-width: $screen-sm-min) {
            
            margin-bottom: 0;
            max-width: 46%;
            display: block;
            float: left;
            margin-right: 6%;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}


.NewsHomeListItem {
    @include clearfix;
    
    width: 100%;
    margin-bottom: 20px;
    position: relative;
    
    .image {
        display: none;
    }
    
    .title {
        margin: 0 0 15px 0;
        padding: 0;
        
        line-height: 0.7;
        
        padding-left: 40px;
        
        > a {
            font-family: $font-1;
            // color: $color-2;
            text-transform: none;
            font-size: 16px;
            font-weight: 700;
        }
    }
    
    .date {
        position: absolute;
        margin: 0 0 0 0;
        font-family: $font-1;
        font-size: 10px;
        
        .date-d, .date-m  {
            display: block;
            margin: 0 auto;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
        }
        .date-d {
            background-color: $color-black;
            color: $color-3;
            border-radius: 50%;
            width: 29px;
            height: 29px;
            padding: 4px;
        }
        .date-m {
            font-size: 12px;
            text-transform: uppercase;
            margin-top: 3px;
        }
        .date-y {
            display: none;
        }
    }
    
    .desc {
        padding-left: 40px;
        
        font-family: $font-1;
        font-size: 14px;
        line-height: 1.2;
        margin: 0 0 15px 0;
        p {
            font-size: 14px;
            margin: 0;
            padding: 0;
        }
    }
}


// .Btn1HomeNews {
//     margin-top: 25px;
// }