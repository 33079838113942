.bx-wrapper {
    box-shadow: none;
    border: none;
    background: transparent;
    
    overflow: visible;
    
    margin-bottom: 0;
    
    .bx-controls-direction {        
        a {
            // position: absolute;
            // top: 50%;
            margin-top: -22px;
            // outline: 0;
            width: 15px;
            height: 40px;
            // text-indent: -9999px;
            z-index: 1000;
            
            
            transition-property: opacity;
            transition-duration: .5s;
            &:hover,
            &:focus {
                @include opacity(.8);
            }
        }
    }
    
    .bx-next {
        right: -15px;
        background: url("/templates/default/img/bx/glyph-arrow-right.png") no-repeat;
        background-position: 0;
        
        // &:before {
        //     font-family: "Glyphicons Halflings";
        //     content: "\e079";
        //     display: block;
        //     background-color: red;
        //     color: white;
        //     width: 20px;
        //     height: 20px;
        // }
        
        &:hover,
        &:focus {
            background-position: 0;
        }
    }
    .bx-prev {
        left: -15px;
        background: url("/templates/default/img/bx/glyph-arrow-right.png") no-repeat;
        background-position: 0;
        transform: rotate(180deg);
        transform-origin: 50% 50% 0;
        
        // &:before {
        //     font-family: "Glyphicons Halflings";
        //     content: "\e080";
        //     display: block;
        //     background-color: red;
        //     color: white;
        //     width: 20px;
        //     height: 20px;
        // }
        
        
        &:hover,
        &:focus {
            background-position: 0;
        }
    }
}
