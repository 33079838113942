.breadcrumbs-wrp {
    // display: none;
    background-color: transparent;
    @include clearfix;
    position: relative;  
    z-index: 101;
    color: $color-black;
    .breadcrumb-shadow {
        display: none !important;
        position: absolute;
        left: $grid-gutter-width / 2;
        bottom: 10px;
        z-index: 1;
        font-family: $font-1;
        font-size: 57px;
        font-weight: 400;
        color: rgba($color-black, 0.11);
    }
}
.breadcrumb {
    margin-left: -25px;
    margin-right: -25px;
    padding: 15px 25px 0 25px;
    
    font-family: $font-1;
    font-size: 26px;
    
    @media (max-width: $screen-xs-min) {
        font-size: 16px;
    }
    
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 0;
    background-color: transparent;
    
    > li {
        color: $color-black;
        font-size: 14px;
        
        @media (max-width: $screen-xs-min) {
            display: none;
        }
        
        &:first-child {
            font-size: 26px;
            @media (max-width: $screen-xs-min) {
                font-size: 16px;
                display: block;
            }
        }
        > a {
            position: relative;
            z-index: 2;
            color: $color-black;
            &:hover,
            &:focus {
                color: $color-link-1;
            }
        }
        &.active {
            color: $color-black;
        }
        span {
            position: relative;
            z-index: 2;
            
        }
        
        & + li:before {
            content: "/ ";
            padding: 0 5px;
            color: #000;
                
            @media (max-width: $screen-xs-min) {
                display: none;
            }
        }
    }
}
