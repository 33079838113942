// .page-preise, .page-escort {
//     table {
//         margin-bottom: 20px;
//         tbody {
//             tr:first-child {
//                 font-size: 20px;
//                 font-family: $font-2;
//                 color: $color-2;
//             }
//             tr {
//                 td {
//                     padding: 5px 10px;
//                 }
//             }
//             tr:nth-child(even) {
//                 background-color: #313131;
//             }
//         }
//     }
// }

// .page-escort {
//     table {
//         tr {
//             td:nth-child(2) {
//                 text-align: right;
//             }
//         }
//     }
// }

table.responsive {
  table-layout: auto;
}

.table-striped > tbody > tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.1);
}

.table > thead > tr > th,
.table > thead > tr > td,
.table > tbody > tr > th,
.table > tbody > tr > td,
.table > tfoot > tr > th,
.table > tfoot > tr > td {
    // padding: 8px;
    // line-height: 1.42857;
    // vertical-align: top;
    border-top: none;
}