/**
 * embed-responsive
 */
.embed-responsive {
    .embed-responsive-item {
        pointer-events: none;
    }
}

/**
 * embed responsive for current project
 * height * 100 / width
 */
.embed-responsive {

    &.embed-responsive-001 {
        padding-bottom: 26.68%;
    }

}


.embed-responsive {
    // 1170x372
    &.embed-responsive-001 {
        padding-bottom: 31.79%;
    }

}


.embed-responsive {
    // 1170x372
    &.embed-responsive-map {
        margin-top: 50px;
        // margin-bottom: -30px;
        padding-bottom: 0; // height * 100 / width
        @media (max-width: $screen-sm-max) {
            padding-bottom: 50.38%;
        }
    }

}