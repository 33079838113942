.col-xxs {
    @media (max-width: $screen-xxs-max) {
        display: block;
        float: none;
        clear: both;
        width: 100%;
        margin-bottom: 20px;
    }
}


/**
 * concept of fake row separator
 */

.fake-row {
    display: none;
    clear: both;
}

.fake-row-sm {
    @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {    
        display: block;
    }
}

.fake-row-md {
 @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
     display: block;
 }
} 
.fake-row-lg {
    @media (min-width: $screen-lg-min) {
        display: block;
    }
}



.row.no-pad {
    margin-right: 0;
    margin-left: 0;
}
.row.no-pad > [class*='col-'] {
    padding-right: 0;
    padding-left: 0;
}


// .col-dynamic-left {
//     @include clearfix;
//     float: left;
//     margin-right: 15px;
//     margin-right: 0px;
    
//     .tpl-home & {
//         margin-top: 20px;
//     }
    
//     width: 946px;
//     width: 969px;
//     @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
//         width: 636px;
//     }
    
//     @media (max-width: $screen-sm-max) {
//         width: 100%;
//         float: none;
//     }
    
//     // background-color: #A7E9FF;
// }

// .col-fixed-right {
//     @include clearfix;
//     min-height: 600px;
//     float: right;
    
//     position: relative;
//     right: -21px;
//     z-index: 1;
    
//     // width: 289px;
//     width: 287px;
//     @media (max-width: $screen-sm-max) {
//         margin-top: 30px;
//         width: 100%;
//         float: none;
//         position: static;
//     }
    
    
// }



