.Download {
    @include clearfix;
    // background-color: #FFE4E4;
    
    .sectionTitle {
        margin-top: 20px;
        padding-bottom: 10px;
        width: 100%;
        &:before {
            display: none;
            background-image: url('../img/glyphs/glyph-files.png');
        }
    }
}

.DowloadList {
    list-style-position: outside;
    list-style-type: none;
    margin-left: 0;
    padding-left: 0;
    
    li {
        
        position: relative;
        padding-left: 49px;
        margin-bottom: 30px;
        line-height: 1.2;
        &:before {
            content: " ";
            border-radius: 50%;
            width: 29px;
            height: 29px;
            position: absolute;
            left: 0;
            top: 49%;
            transform: translateY(-50%);
            margin-right: 10px;
            background-image: url('../img/glyphs/glyph-files.png');
        }
        
        a {
            color: $color-black;
            
            &:hover,
            &:focus {
                color: $color-white;
            }
        }
    }
}