.big-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $color-1;
    @include opacity(0.6);
    // @include transition-property(opacity);
    // @include transition-duration(0.5s);
    z-index: 99999998;
    img {
        position: fixed;
        left: 50%;
        margin-left: -128px;
        top: 20%;
        z-index: 99999999;
    }
}
