.photo-links-partners {
    position: relative;
    z-index: auto;
    margin-top: 50px;
    margin-bottom: 20px;
    // &.on-home {        
    //     @media (min-width: $screen-xs-min) {
    //       top: -55px;
    //       margin-bottom: -55px;
    //       margin-top: 0;
    //     }
    // }
    img {
        @include img-responsive;
    }
    .photo-links-item-wrp {
        
        &:last-child {
          .photo-links-item {
            &:last-child:after{
              display: none;
            }
          }
        }
        float: left;
        margin-right: 4px;
        .photo-links-item {
            
            // @include clearfix;
            position: relative;
            display: block;
            max-width: none;
            margin: 0 auto 0 auto;
            margin-top: 0;
            
            @media (max-width: $screen-md-max) {
                margin-bottom: 10px;
            }
            
            &:after {
              display: none;
              content: "\f054";
              font-family: FontAwesome;
              position: absolute;
              right: -10%;
              top: 21%;
              font-size: 40px;
              color: $color-2;
                
                @media (max-width: $screen-sm-max) {
                    display: none;
                }
            }
            
            .image-link {
                @include clearfix;
                // border-radius: 50%;
                display: block;
                width: 100%;
                height: auto;
                // max-width: 276px;
                max-width: none;
                padding-bottom: 0;
                // padding-bottom: 100%;
                // max-height: 276px;
                margin: 0 auto;
                // background-image: url("/templates/default/img/bg/round-placeholder.png");
                // background-position: center top;
                // background-attachment: scroll;
                // background-repeat: no-repeat;
                // background-size: 100% auto;
                position: relative;
                background-color: transparent;
                // background-color: $color-black;
                > img {
                    position: absolute;
                    top: 0;
                    left: 50%;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 0;
                    transform: translateZ(0);
                    transition-property: opacity;
                    transition-duration: 1s;
                    @include img-responsive;
                    border-radius: 0;
                    display: block;
                    max-height: 62px;
                    // width: 94%;
                    &.image-0 {
                        position: static;
                        @include opacity(0);
                    }
                    
                    &.image-1 {
                        transform: translateX(-50%);
                    }
                    
                    &.image-2 {
                        transform: translateX(-50%);
                        @include opacity(0);
                    }
                }
                &:hover {
                    > .image-1 {
                        @include opacity(0);
                    }
                    > .image-2 {
                        @include opacity(1);
                    }
                }
            }
            
            .title,
            .title > a {
                display: block;
                margin: 0 0 20px 0;
                padding: 0;
                font-weight: 700;
                font-family: $font-2;
                font-size: 20px;
                text-align: left;
                text-transform: uppercase;
                text-decoration: none;
                color: $color-black;
                transition-property: color;
                transition-duration: 0.5s;
                
                font-weight: 400;
                font-size: 20px;
                text-transform: uppercase;
                
                &:hover,
                &:focus {
                    color: $color-link-2;
                }
                &.title-2 {
                    margin-top: 0;
                    font-size: 16px;
                }
            }
            
            .title {
                display: none;
                position: static;
                @include clearfix;
                top: 0;
                margin-bottom: 0;
                margin-top: 30px;
            }
            
            .description {
                display: none;
                margin: 5px 0 0;
                padding: 0;
                font-family: $font-1;
                text-align: left;
                font-size: 14px;
                line-height: 1.6;
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li:before {
                        content: "-";
                        position: relative;
                        left: -5px;
                    }
                    li {
                        text-indent: -5px;
                        @media (max-width: $screen-sm-max) {
                            text-align: center;
                        }
                    }
                }
            }
            .arrow-link {
                display: block;
                margin: 22px auto;
                width: auto;
                text-align: center;
                transition-property: opacity;
                transition-duration: 1s;
                &:hover {
                    @include opacity(0.7);
                }
                img {
                    display: inline-block;
                }
            }
        }
    }
}
