.Com {
    @include clearfix;
    // background-color: #FFF8F8;
    margin-top: 25px;
    
    .Col1 {
        @extend .col-xs-12, .col-md-7, .col-lg-5;
    }
    .Col2 {
        @extend .col-xs-12, .col-md-5, .col-lg-3;
    }
    .Col3 {
        @extend .col-xs-12, .col-md-12, .col-lg-4;
    }
    
    .embed-responsive.embed-responsive-map {
        margin-top: 25px;
        border: 1px solid $color-black;
    }
    
}

.ComData {
    margin-top: 25px;
    .title {
        margin-top: 6px;
        font-size: 24px;
        font-weight: 400;
        text-transform: uppercase;
    }
}

.ComPersons {
    margin-top: 30px;
    
    .subtitle {
        margin-bottom: 10px;
        font-weight: 700;
    }
    
}

.PersonList {
    list-style-type: none;
    
    .PersonList-Item {
        display: flex;
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max), (max-width: $screen-xs-max) {
            display: block;
        }
        justify-content: space-between;
        & > * {
            flex: 1;
            @media (min-width: $screen-md-min) and (max-width: $screen-md-max), (max-width: $screen-xs-max) {
                display: block;
                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }
}

.ComLinks {
    
    margin-top: 25px;
    list-style-type: none;
    
    .item {
        display: block;
        line-height: 1.6;
    }
    
    .link {
        font-size: 24px;
        text-transform: uppercase;
        display: inline-block;
        white-space: nowrap;
        &:before {
            content: " ";
            border-radius: 50%;
            display: inline-block;
            width: 29px;
            height: 29px;
            position: relative;
            top: 5px;
            margin-right: 6px;
        }  
        
    }
    
    .Link-Files {
        &:before {
            background-image: url('../img/glyphs/glyph-kartoteka.png');
        }
    }

    .Link-Resolutions {
        &:before {
            background-image: url('../img/glyphs/glyph-uchwaly.png');
        }
    }

    .Link-Works {
        &:before {
            background-image: url('../img/glyphs/glyph-wykonane-prace.png');
        }
    }

    .Link-Notices {
        &:before {
            background-image: url('../img/glyphs/glyph-ogloszenia.png');
        }
    }

    .Link-Gallery {
        &:before {
            background-image: url('../img/glyphs/glyph-galeria.png');
        }
    }
}

.ComAuctions {
    @include clearfix;
    margin-top: 60px;
    .sectionTitle {
        &:before {
            //background-image: url('../img/glyphs/glyph-wykonane-prace.png');
        }
    }
}

.ComWorks {
    @include clearfix;
    margin-top: 60px;
    .sectionTitle {
        &:before {
            background-image: url('../img/glyphs/glyph-wykonane-prace.png');
        }
    }
}

.ComNotices {
    @include clearfix;
    margin-top: 60px;
    .sectionTitle {
        &:before {
            background-image: url('../img/glyphs/glyph-ogloszenia.png');
        }
    }
}

.ComGallery {
    @include clearfix;
    margin-top: 60px;
    .sectionTitle {
        &:before {
            background-image: url('../img/glyphs/glyph-galeria.png');
        }
    }
    .GalleryAlbumThumbs {
        margin-top: 15px;
    }
}