.HomeOffer {
    @include clearfix;
    // background-color: #FFE4E4;
    
    margin-top: 20px;
    
    .sectionTitle {
        &:before {
            background-image: url('../img/glyphs/glyph-oferta.png');
        }
    }
    
    .HomeOfferBlocks {
        position: relative;
        
        @media (min-width: $screen-md) {
            height: 1530px;
            // background-color: #FFB7B7;
        }
        @media (min-width: $screen-lg) {
            height: 1300px;
            // background-color: #FFB7B7;
        }
    }
    
    .HomeOfferBlock {
        
        position: relative;
        background-repeat: no-repeat;
        background-size: auto 100%;
        @media (min-width: $screen-md) {
            position: absolute;
        }
        
        .HomeOfferBlockCnt {
            position: relative;
            // background-color: #FFDFDF;
        }
        
        &.HomeOfferBlock-1 {
            // background-color: #90b4d0;
            
            @media (min-width: $screen-md) {
                background-image: url("../img/bg/bg-home-prawo.png");
                width: 66.6%;
                height: 33.3%;
                top: 0;
                left: 0;
            }
            
            .HomeOfferBlockCnt {
                @media (min-width: $screen-md) {
                    width: 50%;
                    top: 37%;
                    left: 49%;
                }
            }
        }
        &.HomeOfferBlock-2 {
            // background-color: #83a1b9;
            
            @media (min-width: $screen-md) {
                background-image: url("../img/bg/bg-home-ksiegowosc.png");
                width: 33.4%;
                height: 66.6%;
                top: 0;
                left: 66.6%;
            }
            
            .HomeOfferBlockCnt {
                @media (min-width: $screen-md) {
                    width: 73%;
                    top: 39%;
                    left: 13%;
                }
            }
        }
        &.HomeOfferBlock-3 {
            // background-color: #F1FFF9;
            
            @media (min-width: $screen-md) {
                width: 66.6%;
                height: 33.3%;
                top: 33.33%;
                left: 0;
            }
            
            .HomeOfferBlockCnt {
                @media (min-width: $screen-md) {
                    width: 85%;
                    top: 14%;
                    left: 7%;
                }
            }
        }
        &.HomeOfferBlock-4 {
            // background-color: #8eb1cc;
            
            @media (min-width: $screen-md) {
                background-image: url("../img/bg/bg-home-techniczne.png");
                width: 100%;
                height: 33.3%;
                top: 66.6%;
                left: 0;
            }
            
            .HomeOfferBlockCnt {
                @media (min-width: $screen-md) {
                    width: 61%;
                    top: 23%;
                    left: 34%;
                }
            }
        }
        
    }
    
    .title {
        font-size: 18px;
        font-weight: 700;
        text-transform: none;
        margin-bottom: 0;
        font-family: $font-1;
    }
    
    p {
        margin: 0 0 15px 0;
    }
    
    ul {
        list-style-type: none;
        margin: 0 0 15px 0;
        padding: 0;
        
        li {
            line-height: 1.6;
            
            &:before {
                content: "- ";
            }
            
            p {
                line-height: inherit;
                margin: 0 0 0 0;
            }
        }
    }
}