.GalleryAlbum {
    @include clearfix;
}

.GalleryAlbumName {
    .title {
        font-family: $font-2;
    }
}

.GalleryAlbumDesc {
    .title {
        font-family: $font-1;
        text-transform: none;
        font-weight: 400;
        font-size: 14px;
    }
}

.GalleryAlbumThumbs {
    margin-top: 30px;
    list-style-type: none;
    
    display: flex;                      // display:         flex | inline-flex;
    flex-direction: row;                // flex-direction:  row | row-reverse | column | column-reverse;
    flex-wrap: wrap;                    // flex-wrap:       nowrap | wrap | wrap-reverse;
    align-items: stretch;               // align-items:     flex-start | flex-end | center | baseline | stretch;
    align-content: flex-start;          // align-content:   flex-start | flex-end | center | space-between | space-around | stretch;
    justify-content: space-between;     // justify-content: flex-start | flex-end | center | space-between | space-around;
    
    .thumb {
        position: relative;
        display: inline-block;
        vertical-align: top;
        
        // background-color: #e5e5e5;
        
        margin-bottom: 18px;
        // padding-bottom: 20px;
        
        min-width: 0;
        
        order: 0;
        align-self: auto;               // align-self:      auto | flex-start | flex-end | center | baseline | stretch;
        
        width: 19%;
        flex: 0 1 19%;
        
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            width: 24%;
            flex: 0 1 24%;
        }
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            width: 24%;
            flex: 0 1 24%;
        }
        @media (min-width: $screen-xs-min) and (max-width: $screen-xs-max) {
            width: 32%;
            flex: 0 1 32%;
        }
        @media (max-width: $screen-xxs-max) {
            width: 90%;
            flex: 1 0 90%;
        }

        .link-img {
            display: block;
            margin: 0 auto;
            
            .img {
                display: block;
                margin: 0 auto;
            }
        }
                
                
        // .desc {
        //     text-align: justify;
        //     padding: 15px;
        //     font-family: $font-1;
        //     font-size: 14px;
        //     line-height: 1.1;
        // }
        
        // .link {
        //     display: block;
        //     text-align: center;
        //     margin-bottom: 5px;
        //     position: absolute;
        //     bottom: 0;
        //     width: 100%;
        // }
    }
}

.GalleryAlbumPagination {
    @extend .Pagination;
}