img {
    max-width: 100% !important;
    height: auto !important;
}

.content {
    
    img.justifyright {
        float: right;
        margin-left: 1em;
    }
    img.justifyleft {
        float: left;
        margin-right: 1em;
    }
    
    img[style="float: left;"] {
        margin-right: 1em;
    }
    
    img[style="float: rigt;"] {
        margin-left: 1em;
    }
}