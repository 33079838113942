.NewsPost {
    
    @include clearfix;
    margin-top: 20px;
    
    .image {
        
        @media (min-width: $grid-float-breakpoint) {            
            position: relative;
            top: 10px;
        }
        margin-bottom: 10px;
        float: left;
        // max-width: 200px;
        // margin-right: 20px;
        // margin-bottom: 20px;
    }
    
    .ColLeft {
        display: none;
        @extend .col-xs-12, .col-sm-4, .col-md-3;
    }
    .ColRight {
        //@extend .col-xs-12, .col-sm-8, .col-md-9;
        @extend .col-xs-12;
    }
    
    .moreLink {
        float: right;
        margin-right: 20px;
    }
    
}
    
.NewsPostData {
    
    @include clearfix;
    position: relative;
    top: 0;
    // border-bottom: 1px solid lighten($color-gray, 30%);
    padding-bottom: 10px;
    
    
    .title {
        
        margin: 0 0 15px 0;
        padding: 0;
        padding-left: 40px;
        line-height: 0.7;
        
        > a {
            font-family: $font-1;
            // color: $color-2;
            // text-transform: uppercase;
            font-size: 16px;
            font-weight: 700;
        }
    }
    
    .date {
        position: absolute;
        top: 0;
        margin: 0 0 0 0;
        font-family: $font-1;
        font-size: 10px;
        
        .date-d, .date-m  {
            display: block;
            margin: 0 auto;
            text-align: center;
            font-weight: 700;
            font-size: 14px;
        }
        .date-d {
            background-color: $color-black;
            color: $color-3;
            border-radius: 50%;
            width: 29px;
            height: 29px;
            padding: 4px;
        }
        .date-m {
            font-size: 12px;
            text-transform: uppercase;
            margin-top: 3px;
        }
        .date-y {
            display: none;
        }
    }
    
    .entry {
        margin-top: 10px;
        padding-left: 40px;
        p {
            text-align: justify;
        }
    }
    
}