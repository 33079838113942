// ::: fix on webkit for fade to work 
.carousel-inner {
  > .item {
    // WebKit CSS3 transforms for supported devices
    @media all and (transform-3d), (-webkit-transform-3d) {
        transition-transform: 0.6s;
        backface-visibility: hidden;
        perspective: 1000;

        &.next,
        &.active.right {
            transform: translate3d(0, 0, 0);
        }
        &.prev,
        &.active.left {
            transform: translate3d(0, 0, 0);
        }
        &.next.left,
        &.prev.right,
        &.active {
            transform: translate3d(0, 0, 0);
        }
    }
  }
}

// ::: carousel fade
.carousel-fade {
    .carousel-inner {
        .item {
            opacity: 0;
            transition-property: opacity;
            transition-duration: 0.6s;
        }

        .active {
            opacity: 1;
        }

        .active.left,
        .active.right {
            left: 0;
            opacity: 0;
            z-index: 1;
        }

        .next.left,
        .prev.right {
            left: 0;
            opacity: 1;
        }
    }

    .carousel-control {
        z-index: 2;
    }
}
