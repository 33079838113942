.Projects {


    
}

.TableResponsive {
    margin-left: -8px;
    padding-left: 8px;
}

.ProjectsTable {

    margin-left: -8px;
    margin-right: -8px;
    
    > tbody {
        
        > tr {
            
            > td {
                border-bottom: 1px solid lighten($color-gray, 35%);
                font-family: $font-1;
                vertical-align: middle;
                
                @media (max-width: $screen-xs-max) {
                    @include clearfix;
                    display: block;
                    border-bottom: none;
                    font-size: 14px !important;
                    background-color: transparent !important;
                    &:first-child {
                        padding-top: 30px;
                    }
                    &:last-child {
                        border-bottom: 1px solid lighten($color-gray, 35%);
                        padding-bottom: 30px;
                    }
                }
            }
        }
    }
    
    
    .title {
        font-family: $font-2;
        // color: $color-2;
        font-weight: 700;
        font-size: 14px;
        text-transform: uppercase;
    }
    
    .desc {
        // background-color: red;
    }
    
    // .price {
    //     background-color: lighten($color-gray, 35%);
    // }
    // .date {
    //     white-space: nowrap;
    //     font-size: 13px;
    // }
    
    .action {
        text-align: right;
    }
    
}

.ProjectsPagination {
    @extend .Pagination;
}