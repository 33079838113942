// navbar-main
$navbarMain-bg-color: $color-black;
$navbarMain-border-color: $color-white;
$navbarMain-font-family: $font-1;
$navbarMain-font-size: 18px;

// menu-main
$menuMain-bg-color: $color-black;
$menuMain-color-link: $color-white;
$menuMain-color-link-hover: $color-2;
$menuMain-color-link-active: $color-2;
$menuMain-color-link-active-hover: $color-2;
$menuMain-bg-color-link-active: transparent;
$menuMain-bg-color-link-hover: transparent;

// menu-main dropdown
$menuMain-dropdownMenu-font-size: 18px;
$menuMain-dropdownMenu-bg-color: $color-black;
$menuMain-dropdownMenu-color-link: $color-white;
$menuMain-dropdownMenu-color-link-hover: $color-2;
$menuMain-dropdownMenu-color-link-active: $color-2;
$menuMain-dropdownMenu-color-link-active-hover: $color-2;
$menuMain-dropdownMenu-bg-color-link-active: transparent;
$menuMain-dropdownMenu-bg-color-link-hover: transparent;

// menu-main dropdown floated
$menuMain-dropdownMenuFloated-bg-color: transparent;
$menuMain-dropdownMenuFloated-color-link: $menuMain-color-link;
$menuMain-dropdownMenuFloated-color-link-hover: $menuMain-color-link-hover;
$menuMain-dropdownMenuFloated-color-link-active: $menuMain-color-link-active;
$menuMain-dropdownMenuFloated-color-link-active-hover: $menuMain-color-link-active-hover;
$menuMain-dropdownMenuFloated-bg-color-link-active: $menuMain-bg-color-link-active;
$menuMain-dropdownMenuFloated-bg-color-link-hover: $menuMain-bg-color-link-hover;

// navbar toggle
$navbarToggle-border-color: $color-2;
$navbarToggle-border-color-hover: $color-2;
$navbarToggle-bg-color-hover: $color-2;
$navbarToggle-iconBar-bg-color: $color-2;
$navbarToggle-iconBar-bg-color-hover: $color-black;


.NavbarMain {
    
    position: relative;
    z-index: 100;
    
    background-color: $navbarMain-bg-color;
    
    border-width: 0;
    border-color: $navbarMain-border-color;
    border-radius: 0;
    
    font-size: $navbarMain-font-size;
    font-family: $navbarMain-font-family;
    
    margin-bottom: 0;
    
    > .container {
        position: relative;
    }
    
}

.NavbarMain .NavbarBrand {
    
    position: relative;
    z-index: 100;
    padding: 0 10px;
    // top: -58px;
    // left: 25px;
    // width: 130px;
        
    position: absolute;
    top: 88px;
    right: 274px;
    @media (max-width: $screen-xs-max) {
        left: 36px;
        right: auto;
        top: 0;
        // display: none;
    }        
        
    .tpl-home & {
        position: absolute;
        top: 67px;
        left: 36px;
        right: auto;
        @media (max-width: $screen-xs-max) {
            top: 0;
            display: block;
        }
    }
    
    img {
        &.on-page {
            @media (max-width: $screen-xs-max) {
                // left: 36px;
                // right: auto;
                // top: 0;
                display: none;
            } 
            .tpl-home & {
                display: none;

            }
        }
        
        &.on-home {
            display: none;
            @media (max-width: $screen-xs-max) {
                // left: 36px;
                // right: auto;
                // top: 0;
                display: block;
            } 
            .tpl-home & {
                display: block;
            }
        }
    }

    @media (max-width: $screen-xs-max) {
        // top: -16px;
        background-color: rgba($color-3, 0.7);
        img {
            max-height: $navbar-height;
        }
    }

    img {
        @include img-responsive;
    }
}

.NavbarMain .NavbarToggle {
    
    border-color: $navbarToggle-border-color;
    
    .icon-bar {
        background-color: $navbarToggle-iconBar-bg-color;
    }
    
    &:hover,
    &:focus {
        border-color: $navbarToggle-border-color-hover;
        background-color: $navbarToggle-bg-color-hover;
        
        .icon-bar {
            background-color: $navbarToggle-iconBar-bg-color-hover;
        }
    }
    
    &.collapsed {
        transform: rotate(0deg);
        backface-visibility: hidden;
        transform: translateZ(0);
    }
    & {
        transition-property: transform;
        transition-duration: 0.5s;
        transform: rotate(180deg);
    }
    
}

.MenuMain {
    
    position: relative;
    z-index: 100; // MenuMain in collapsed view sometimes have to be over the NavbarBrand
    background-color: $menuMain-bg-color;
    
    a {
        text-transform: uppercase;
        font-weight: 400;
    }
    
    > .navbar-nav { // ul
        
        @media (min-width: $grid-float-breakpoint) {
            float: right;
            position: relative;
            right: -$grid-gutter-width / 2;
            // > li {
            //     float: left;
            //     > a {
            //         padding-top:    $navbar-padding-vertical + $navbar-padding-vertical-modifier;
            //         padding-bottom: $navbar-padding-vertical - $navbar-padding-vertical-modifier;
            //     }
            // }
        }
        
        > li, .dropdown.open {
            
            > a {
                
                color: $menuMain-color-link;
                background-color: $menuMain-dropdownMenu-bg-color;
                
                &:hover,
                &:focus {
                    color: $menuMain-color-link-hover;
                    background-color: $menuMain-bg-color-link-hover;
                }
            }
            
            &.active {
                
                > a {
                    
                    color: $menuMain-color-link-active;
                    background-color: $menuMain-bg-color-link-active;
                    
                    &:hover,
                    &:focus {
                        color: $menuMain-color-link-active-hover;
                        background-color: $menuMain-bg-color-link-hover;
                    }
                }
            }
        }        
        
        > .dropdown { // li
            
            > .dropdown-toggle { // a
            
            }
            
            > .dropdown-menu { //ul
                
                border-radius: 0;
                
                background-color: $menuMain-dropdownMenu-bg-color;
                
                @media (max-width: $grid-float-breakpoint-max) {
                    background-color: $menuMain-dropdownMenuFloated-bg-color;
                }
                
                border: none;
                
                > li {
                    
                    font-size: $menuMain-dropdownMenu-font-size;
                    
                    border-color: $color-white;
                    border-style: solid;
                    border-width: 0;
                    border-top-width: 0;
                    border-left-width: 0;
                    border-right-width: 0;
                    
                    &:last-child {
                        border-bottom-width: 0;
                    }
                
                    
                    > a {
                        
                        font-weight: 400;
                        color: $menuMain-dropdownMenu-color-link;
                        @media (max-width: $grid-float-breakpoint-max) {
                            color: $menuMain-dropdownMenuFloated-color-link;
                        }
                        
                        &:hover,
                        &:focus {
                            color: $menuMain-dropdownMenu-color-link-hover;
                            background-color: $menuMain-dropdownMenu-bg-color-link-hover;
                            @media (max-width: $grid-float-breakpoint-max) {
                                color: $menuMain-dropdownMenuFloated-color-link-hover;
                                background-color: $menuMain-dropdownMenuFloated-bg-color-link-hover;
                            }
                            
                        }
                    }
                    
                    &.active {
                        > a {
                            
                            color: $menuMain-dropdownMenu-color-link-active;
                            background-color: $menuMain-dropdownMenu-bg-color-link-active;
                            @media (max-width: $grid-float-breakpoint-max) {
                                color: $menuMain-dropdownMenuFloated-color-link-active;
                                background-color: $menuMain-dropdownMenuFloated-bg-color-link-active;
                            }
                            
                            
                            &:hover,
                            &:focus {
                                color: $menuMain-dropdownMenu-color-link-active-hover;
                                background-color: $menuMain-dropdownMenu-bg-color-link-hover;
                                @media (max-width: $grid-float-breakpoint-max) {
                                    color: $menuMain-dropdownMenuFloated-color-link-active-hover;
                                    background-color: $menuMain-dropdownMenuFloated-bg-color-link-hover;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}