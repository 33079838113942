.photo-links {
    position: relative;
    z-index: 500;
    margin-top: 60px;
    margin-bottom: 20px;
    // &.on-home {        
    //     @media (min-width: $screen-xs-min) {
    //       top: -55px;
    //       margin-bottom: -55px;
    //       margin-top: 0;
    //     }
    // }
    img {
        @include img-responsive;
    }
    .photo-links-item-wrp {
        .photo-links-item {
            // @include clearfix;
            position: relative;
            display: block;
            max-width: 276px;
            margin: 0 auto 0 auto;
            .image-link {
                @include clearfix;
                // border-radius: 50%;
                display: block;
                width: 100%;
                height: auto;
                // max-width: 276px;
                max-width: 170px;
                padding-bottom: 220px;
                // padding-bottom: 100%;
                // max-height: 276px;
                margin: 0 auto;
                // background-image: url("/templates/default/img/bg/round-placeholder.png");
                // background-position: center top;
                // background-attachment: scroll;
                // background-repeat: no-repeat;
                // background-size: 100% auto;
                position: relative;
                background-color: transparent;
                // background-color: $color-black;
                > img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-left: 0;
                    padding-right: 0;
                    transform: translateZ(0);
                    transition-property: opacity;
                    transition-duration: 1s;
                    @include img-responsive;
                    border-radius: 50%;
                    display: block;
                    // width: 94%;
                    &.image-0 {
                        position: static;
                        @include opacity(0);
                    }
                    &.image-2 {
                        @include opacity(0);
                    }
                }
                &:hover {
                    > .image-1 {
                        @include opacity(0);
                    }
                    > .image-2 {
                        @include opacity(1);
                    }
                }
            }
            .title,
            .title > a {
                display: block;
                margin: 10px 0 0 0;
                padding: 0;
                font-weight: 700;
                font-family: $font-2;
                font-size: 20px;
                text-align: center;
                text-transform: uppercase;
                text-decoration: none;
                color: $color-link-1;
                transition-property: color;
                transition-duration: 0.5s;
                &:hover,
                &:focus {
                    color: $color-link-2;
                }
                &.title-2 {
                    margin-top: 0;
                    font-size: 16px;
                }
            }
            .description {
                margin: 5px 55px 0 55px;
                padding: 0;
                font-family: $font-1;
                text-align: left;
                ul {
                    list-style-type: none;
                    margin: 0;
                    padding: 0;
                    li:before {
                        content: "-";
                        position: relative;
                        left: -5px;
                    }
                    li {
                        text-indent: -5px;
                    }
                }
            }
            .arrow-link {
                display: block;
                margin: 22px auto;
                width: auto;
                text-align: center;
                transition-property: opacity;
                transition-duration: 1s;
                &:hover {
                    @include opacity(0.7);
                }
                img {
                    display: inline-block;
                }
            }
        }
    }
}






.photo-links {
    
    
    // @media (max-width: $screen-sm-max) {
    //     display: none !important;
    // }
    
    // margin-top: 18%;
    
    // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    //     margin-top: 27%;
    // }
    
    @media (max-width: $screen-sm-max) {
        // margin-top: 210px;
        // margin-top: 12%;
    }
    
    // &.on-home {  
    
    //     margin-top: 60px;
          
    //     @media (min-width: $screen-xs-min) {
    //         margin-top: 0;
    //     }
    // }
    
    img {

    }
    .photo-links-item-wrp {
        
        .photo-links-item {
            &:after {
              content: "\f054";
              font-family: FontAwesome;
              position: absolute;
              right: -10%;
              top: 21%;
              font-size: 40px;
              color: $color-2;
                
                @media (max-width: $screen-sm-max) {
                    display: none;
                }
            }
        }
        
        &:last-child {
          .photo-links-item {
            &:last-child:after{
              display: none;
            }
          }
        }
        
        
        
        .photo-links-item {
            margin-top: 40px;
            
            @media (max-width: $screen-xs-max) {
                margin-bottom: 40px;
            }
            
            .image-link {

                
                > img {


                    
                    
                    &.image-0 {

                    }
                    
                    &.image-2 {

                    }
                }
                
                &:hover {
                    > .image-1 {

                    }
                    > .image-2 {

                    }
                }
            }
            .title {
                position: relative;
                @include clearfix;
                top: -72px;
                margin-bottom: -72px;
            }
            .title, .title > a {
                
                font-size: 22px;
                text-transform: none;
                // background-color: #000;
                
                &:hover,
                &:focus {

                }
                
                
                &.title-2 {
                }
            }
            
            .description {
                ul {
                    li {
                        @media (max-width: $screen-sm-max) {
                            text-align: center;
                        }
                    }
                }
            }
            
            .arrow-link {

                &:hover {

                }
                img {

                }
            }
        }
    }
}
