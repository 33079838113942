.Table {
    
    @extend .table, .table-hover, .table-condensed;
    
    font-size: 14px;
    
    .title {
        font-size: 14px;
        margin: 0;
        padding: 0;
    }
    
}

.Table {
    border: none;
}

.Table > tbody > tr {
    
    &:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.04);
    }
    
    &:hover {
        background-color: #BBDAF2;
    }
}

.Table > tbody > tr > td,
.Table > thead > tr > th,
.Table > thead:first-child > tr:first-child > th {
    
    border-top: 1px solid #7EA2BF;
    border-bottom: 1px solid #7EA2BF;
    border-left: 1px solid #7EA2BF;
    border-right: 1px solid #7EA2BF;
    
}
