h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
    color: $color-black;
    font-family: $font-1;
    margin: 0;
    padding: 0;
    font-weight: 700;
}

h1, .h1 {
    margin-top: 15px;
    margin-bottom: 15px;
    font-size: 26px;
    // text-transform: uppercase;
}

h2, .h2 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 22px;
    // text-transform: uppercase;
}

h3, .h3 {
    font-family: $font-1;
    margin-top: 6px;
    margin-bottom: 6px;
    font-size: 18px;
}

h4, .h4 {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 18px;
}

h5, .h5 {
    margin-top: 4px;
    margin-bottom: 4px;
    font-size: 16px;
}

h6, .h6 {
    margin-top: 3px;
    margin-bottom: 3px;
    font-size: 16px;
}

p {
    margin: 0;
    padding: 0;
}

ul, ol {
    margin: 0;
    padding: 0;
}

.content {
    
    
    
    p {
        // text-align: justify;
        margin-bottom: 15px;
    }
    
    ul, ol {
        margin: 0 0 15px 0;
        padding: 0  0 0 40px;
    }
    
    .justifyfull {
        text-align: justify;
    }
    .justifyright {
        text-align: right;
    }
    .justifyleft {
        text-align: left;
    }
    
}

.tpl-home {
    .content {
        h1 {
            // margin-bottom: 15px;
        }
        h2 {
            // margin-bottom: 15px;
            // text-align: center;
            // color: $color-white;
            // margin-top: -20px;
            // margin-bottom: 40px;
        }
        h3 {
            
        }
    }
}

table.responsive {
    h1, h2, h3, h4, h5, h6 {
        margin-top: 10px;
        margin-bottom: 5px;
    }
}

h1.with-line {
    display: block;
    position: relative;
    text-align: left;
    margin-top: 50px;
    margin-bottom: 30px;
    
    &:before {
        background: #ddd;
        content: "";
        display: block;
        height: 1px;
        position: absolute;
        top: 78%;
        width: 100%;
        left: 0;
        @media (max-width: $screen-xs-max) {
            display: none;
        }
    }
    
    span {
        background: #fff;
        @media (max-width: $screen-xs-max) {
            background: transparent;
        }
        padding: 0 15px 0 0;
        position: relative;
        z-index: 1;
    }
}

.sectionTitle {
    font-family: $font-1;
    font-size: 24px;
    font-weight: 400;
    color: $color-black;
    text-transform: uppercase;
    display: inline-block;
    margin-bottom: 20px;
    border-bottom: 0 solid #738fa5;
    
    .Inner & {
        font-size: 18px;
    }
    
    &--underlined {
        border-width: 1px;
    }
    &:before {
        content: " ";
        background-image: url('../img/glyphs/glyph-arrow-down.png');
        border-radius: 50%;
        display: inline-block;
        width: 29px;
        height: 29px;
        position: relative;
        top: 5px;
        margin-right: 10px;
    }
    &--WithLink {
        .smaller {
            font-size: 14px;
            .title {
                font-size: 14px;
                display: inline;
                font-weight: 400;
            }
        }
    }    
}