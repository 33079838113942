a {
    color: $color-link-1;
    text-decoration: none;
    
    transition-property: color, background-color;
    transition-duration: 0.5s;
    
    img {
        transition-property: opacity;
        transition-duration: 0.5s;
    }
}

.active > a {
    color: $color-link-1;
    text-decoration: none;
}

a:hover,
a:focus {
    color: $color-link-2;
    text-decoration: none;
    
    img {
        opacity: 0.8;
    }
}