#Calendar {
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    width: 280px;
    margin: auto;
}

#Calendar .calendar {
    border-collapse: collapse;
    margin: auto;
}
#Calendar .cover {
    opacity: 0.1;
    position: relative;
    background: #fff;
    display: none;
    margin: auto;
}

#Calendar .month,
#Calendar .prev,
#Calendar .next {text-align: center; font-weight: bold;}

#Calendar .calendar .cell {
  padding: 0 4px 0 0;
  text-align:right;
  font-size: 10px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  height: 32px;
}
#Calendar .calendar .cell:hover {opacity: 0.7;}

#Calendar .calendar .isevent {cursor: pointer;}

#Calendar .calendar .weekend {color: #c43c35 !important;}
#Calendar .calendar .today {background-color: #fcdd76; font-weight: bold; color: black !important;}
#Calendar .calendar .isevent {background-color: #46a546; color: #fff;}

#Calendar .calendar .cell.active {font-weight: bold; background-color: #4f7942;}

#Calendar .event {
    color: black;
    font-size: 11px;
    text-align: left;
    position:absolute;
    z-index: 10;
    display: none;
    background: #fff;
    border: 1px solid #AAA;
    border-radius: 2px;
    box-shadow: 0px 3px 3px #555555;
    padding: 5px;
    min-height: 50px;
    min-width: 200px;
    max-height: 150px;      
    max-width: 300px;
    margin: -10px 0 0 10px;
    overflow: auto;
}
