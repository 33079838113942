.error {
    color: $brand-danger;
}

.relative {
    position: relative;
}

.transparent {
    @include opacity(0);
}