.Pagination {
    
    text-align: center;
    
    .pagination {
        > li {
            > a, 
            > span {
                border: none;
                background-color: transparent;
                color: $color-link-1;
            }
            > a {
                &:hover,
                &:focus,
                &:active {
                    color: $color-link-2;
                }
            }
            
            &.active {
                > span {
                    color: $color-link-2;
                    &:hover,
                    &:focus,
                    &:active {
                        background-color: transparent;
                    }
                }
            }
        }
    }
}