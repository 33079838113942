.scrollTop {
    display: inline-block;
    border-radius: 5px;

    position: fixed;
    z-index: 9999;
    bottom: 0;
    right: 0;
    margin: 0.4em 3em;
    font-size: 20px;
    padding: 8px 15px 10px 15px;
    text-decoration: none;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: $color-2;
    color: $color-3;

    &:hover {
        box-shadow: 0px 0px 5px rgba($color-2, 0.5);
        background: $color-1;
        color: $color-2;
    }

    @media only screen and (max-width: $screen-xs-max) {
        margin-bottom: 15px;
        margin-right: 15px;
    }
}