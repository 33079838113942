
// bootstrap fonts ( trailing / )
// fonts from sass version show up with errors so get from dist
// $icon-font-path:         "../lib/bootstrap-sass/assets/fonts/";
 
$icon-font-path:            "../lib/bootstrap-sass/assets/fonts/bootstrap/";

// font-awesome fonts  ( no trailing / )

$fa-font-path:              "../lib/font-awesome/fonts";


$color-gray:                #959595; //#808080;
$color-gray-light:          #eeeeee;

$color-black:               #000;
$color-white:               #fff;

$color-red:                 #ff0000;
$color-red-light:           lighten($color-red,5%);

$color-1:                   $color-black;
$color-2:                   #6998B6;
$color-3:                   #94b9d6;
$color-4:                   $color-white;

$color-link-1:              $color-black;
$color-link-2:              $color-white;

// $color-navbar-link-1:       $color-black;
// $color-navbar-link-2:       $color-white;

$color-bg-1:                #425fa8; // dark blue
$color-bg-2:			    #00992b; // green

$font-1:                    "Open Sans", sans-serif;
$font-2:                    "Open Sans Condensed", sans-serif;

$font-size-menu-1:      	18px;
$font-size-menu-footer-1:	13px;
$font-size-menu-langs:  	12px;

$navbar-padding-vertical-modifier: 0; //10px;
