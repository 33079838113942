.popup-modal{
    @include opacity(0);
    transition-property: opacity;
    transition-duration: 1.5s;
    .modal-dialog {
        .modal-content {
            
            
            @include box-shadow(none);
            .modal-header {
                border-bottom: none;
                .close {
                    @include opacity(1);
                    text-shadow: none;
                    .glyphicon {
                        color: $color-2;
                    }
                }
                .modal-title {
                    display: none;
                }
            }
            .modal-body{
                    img {
                        max-width: 100% !important;
                        height: auto !important;
                    }
            }
            .modal-footer {
                border-top: none;
                display: none;
            }
        }
    }
}

// --- modal vertically centered
.modal-dialog-center {
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
}

.modal-body {
        overflow-y: auto;
}
.modal-footer {
        margin-top: 0;
}

@media (max-width: 767px) {
    .modal-dialog-center {
        width: 100%;
    }
}