.gallery-2-wrp {
    @include clearfix;
    margin-top: 30px;
    margin-bottom: 20px;

    @media (max-width: $screen-xs-max) {
        // max-width: 535px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
    
    @media (max-width: 607px) {
        // max-width: 263px !important;
        margin-left: auto !important;
        margin-right: auto !important;
    }
}

.gallery-2-wrp.gallery-2-wrp-thumbnails {
    @media (max-width: $screen-xs-max) {
        max-width: none !important;
    }
}

.gallery-2-wrp {
    .gallery-2-title {
        font-size: 15px;
        font-family: $font-2;
    }
    .gallery-2-description {
        font-size: 14px;
        font-family: $font-1;
        margin-bottom: 15px;
    }
}

.gallery-2 {
    
    
    .gallery-2-photo-wrp {
        background-color: transparent;
        // border: 1px solid $color-gray;
        // height: 278px;
        // float:left;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        
        // display: flex;
        // justify-content: center;
        // align-items: center;
        
        // @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        //     max-width: 228px !important;
        //     height: 228px;
        // }
        
        
        // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        //     max-width: 234px !important;
        //     height: 234px;
        // }
        
        // @media (max-width: $screen-xs-max) {
        //     max-width: 263px !important;
        // }
        
        // .gallery-2-wrp-thumbnails & {
        //     position: relative;
        //     width: 275px;
        //     height: 275px;
            
        //     @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
        //         width: 275px;
        //         height: 275px;
        //     }
            
            
        //     @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
        //         width: 275px;
        //         height: 275px;
        //     }
            
        //     @media (max-width: $screen-xs-max) {
        //         width: 275px;
        //         height: 275px;
        //     }
        // }
        
    }
    
    
    .gallery-2-photo {
        // border: none;
        // padding: 0;
        // margin: 0 5px 5px 5px;
        // float: left;        
        
        
        // @include clearfix;
        margin: 1px;
        border: 1px solid $color-gray;
        padding: 5px;
        
        // position: absolute;
        // top: 50%;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // width: 177px;
        
        a {
            display: block;
            max-width: 100%;
            
            
            transition-property: opacity;
            transition-duration: 0.5s;
            &:hover,
            &:focus {
                @include opacity(.8);
            }
            
            img {
                
                @include img-responsive;
                
                // max-width: 369px;
                // height: auto;
                
                // @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
                //     max-width: 303px;
                //     height: auto;
                // }
                // @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
                //     max-width: 229px;
                //     height: auto;
                // }
                // @media (max-width: $screen-xs-max) {
                //     max-width: 100%;
                //     height: auto;
                // }
            }
        }
    }
    
}

.gallery-2-cmp-slider {
    max-width: 95%;
    display: block;
    margin: 0 auto;
}

.gallery-2-cmp-thumbnails {
    text-align: center;
}

.gallery-2-thumbnails {
    
    @include clearfix;
    
    
    .gallery-2-photo-wrp {
        position: relative;
        // width: 275px;
        // height: 275px;
        
        display: inline-block;
        
        background-color: transparent;
        margin: 5px;
        .gallery-2-photo {
            // max-width: 90% !important;
        }
        @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
            max-width: 199px !important;
            // max-height: 225px !important;
        }
        @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
            // max-width: 230px !important;
            // max-height: 230px !important;
        }
        
        @media (max-width: $screen-xs-max) {
            // max-width: 31% !important;
            // max-width: 209px !important;
            // max-height: 209px !important;
            // margin: 1%;
        }
        
    }

}
