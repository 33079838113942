.btn-1 {
    font-family: $font-2;
    text-decoration: none;
    text-transform: uppercase;
    color: $color-black;
    font-size: 18px;
    font-weight: 400;
    background-color: $color-2;
    transition-duration: 0.6s;
    transition-property: background-color;
    border-radius: 0;
    &:focus,&:active {
        @extend .btn-1;
    }
    &:hover {
        background-color: $color-bg-2;
        color: $color-black;
    }
}

.btn {
    
    transition-property: color, background-color;
    transition-duration: 0.5s;
    
    &:hover,
    &:focus,
    &:active {
        
    }
}

.Btn1 {
    border-radius: 0;
    font-family: $font-1;
    font-weight: 400;
    font-size: 12px;
    background-color: $color-black;
    color: $color-3;
    text-transform: uppercase;
    
    padding: 4px 8px;
    
    &:hover,
    &:focus,
    &:active {
        background-color: lighten($color-2, 40%);
        color: $color-black;
        text-decoration: none;
    }
}

.Btn2 {
    @extend .Btn1;
    font-size: 18px;
}