.Training {
    
    margin-top: 15px;
    
    .title {
        font-family: $font-2;
        font-size: 18px;
        text-transform: uppercase;
        color: $color-2;
    }
    
}

.TrainingData {
    
    @include clearfix;
    
    margin-top: 0;
    padding-bottom: 15px;
    
    
    box-shadow: 0px 1px 0px 0px rgba($color-gray, 0.5);
    padding-bottom: 15px;
    margin-bottom: 15px;
    
    
    .key {
        font-family: $font-2;
        font-size: 16px;
        font-weight: 700;
    }
    
    .val {
        font-family: $font-1;
        font-size: 14px;
        font-weight: 400;
    }
    
    
}

.TrainingMap {
    
    margin-top: 30px;
    
    .title {
        font-family: $font-2;
        font-size: 18px;
        text-transform: uppercase;
        color: $color-black;
    }
    
    .embed-responsive.embed-responsive-map {
        margin-top: 10px;
    }
    
}