.AddressCard {

    text-align: left;
    
    font-size: 14px;
    
    .Item-Key {
        text-transform: none;
    }
    
    .Item-Val {
        font-size: 16px;
    }
    
    .sec2 {
        margin-top: 20px;
    }
}