.lSSlideOuter {
    position: relative;
    overflow: visible;
}

.lSAction {
    
    > a {        
        width: 27px;
        height: 26px;
    }
    
    >.lSPrev {
        background-position: 0 0;
        background-position: -1px -3px;
        left: -24px;
        background-color: #2A2A2A;
        border-radius: 50%;
    }
    
    >.lSNext {
        background-position: -32px 0;
        background-position: -36px -3px;
        right: -24px;
        background-color: #2A2A2A;
        border-radius: 50%;
    }
}


    